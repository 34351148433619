<template>
  <div class="pagination" :class="{'is-mobile': isMobile}" v-if="hasPages">
    <div class="pages" v-if="isMobile">
      <span :class="{disabled: previousPage <= 0}" @click="clickOnPage(previousPage)">&lsaquo; Previous</span>
      <span class="current">Page {{ currentPage.toLocaleString('en') }} / {{ totalPage.toLocaleString('en') }}</span>
      <span :class="{disabled: nextPage <= 0}" @click="clickOnPage(nextPage)">Next &rsaquo;</span>
    </div>
    <div class="pages" v-else>
      <span v-if="previousPage > 0" @click="clickOnPage(previousPage)">&lsaquo; Previous</span>
      <span v-if="firstPage > 0 && firstPage !== previousPage && previousPage > 0" @click="clickOnPage(firstPage)">{{
        firstPage
      }}</span>
      <span v-if="firstPage > 0 && firstPage !== previousPage && previousPage > 0">...</span>
      <span v-if="previousPage > 0" @click="clickOnPage(previousPage)">{{ previousPage.toLocaleString('en') }}</span>
      <span class="current">{{ currentPage.toLocaleString('en') }}</span>
      <span v-if="nextPage > 0" @click="clickOnPage(nextPage)">{{ nextPage.toLocaleString('en') }}</span>
      <span v-if="totalPage > 0 && totalPage !== nextPage && nextPage > 0" class="label">...</span>
      <span v-if="totalPage > 0 && totalPage !== nextPage && nextPage > 0" @click="clickOnPage(totalPage)">{{
        totalPage.toLocaleString('en')
      }}</span>
      <span v-if="nextPage > 0" @click="clickOnPage(nextPage)">Next &rsaquo;</span>
    </div>

    <div class="jump-to-page" v-if="showJumpTo">
      <span class="supplemental label">Jump to: </span>
      <input :ref="jumpRef" v-model="jumpValue" type="number" min="1" :max="totalPage" @keyup.enter="jumpToPage" />
      <mcr-button @click="jumpToPage">Go</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';

export default {
  props: {
    meta: Object,
    showJumpTo: {type: Boolean, default: true},
  },
  data() {
    return {
      jumpRef: 'jump-input',
      jumpValue: null,
    };
  },
  computed: {
    firstPage() {
      return 1;
    },
    totalPage() {
      return Math.ceil(this.meta.total_count / this.meta.limit);
    },
    currentPage() {
      return Math.floor(this.meta.offset / this.meta.limit) + 1;
    },
    nextPage() {
      if (this.currentPage >= this.totalPage) {
        return 0;
      }
      return this.currentPage + 1;
    },
    previousPage() {
      return this.currentPage - 1;
    },
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
    },
    hasPages() {
      if (this.meta.total_count && this.meta.limit) {
        return this.meta.total_count > this.meta.limit;
      }
      return false;
    },
  },
  methods: {
    clickOnPage(page) {
      this.$emit('onSwitchPage', page);
    },
    jumpToPage() {
      const pageNumber = parseInt(this.jumpValue);
      if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= this.totalPage) {
        this.clickOnPage(pageNumber);
      }
    },
  },
  components: {McrButton},
};
</script>

<style scoped lang="scss">
.pagination {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .pages span {
    transition: all 0.2s ease;
    cursor: pointer;
    padding: 0 4px;
    color: $primary-400;

    &.current {
      color: #000;
      cursor: default;
      font-weight: bold;
    }

    &:hover:not(.current) {
      color: $active-color;
      text-decoration: underline;
    }

    &.previousPage,
    &.nextPage {
      white-space: nowrap;
    }

    &.label {
      color: $text-color;
    }
  }

  .jump-to-page {
    display: flex;
    align-items: center;

    .label {
      margin-right: 10px;
    }

    .mcr-button,
    .mcr-button:hover {
      background-color: $mcr-dark-tan;
    }
  }
}

.pagination.is-mobile {
  flex-direction: column;
  align-items: flex-end;

  .pages {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span.current {
      color: $supplemental-text-color;
      cursor: default;
      font-weight: normal;
    }
    span.disabled {
      color: $supplemental-text-color;
      cursor: default;
      pointer-events: none;
    }
  }
  .jump-to-page {
    padding-top: 20px;
  }
}
</style>
